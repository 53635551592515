/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: HelveticaLTWXX-Roman by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/helvetica/pro-regular/
 * Copyright: Copyright © 2014 Monotype Imaging Inc. All rights reserved.
 *
 *
 *
 * © 2020 MyFonts Incn*/

@font-face {
  font-family: "Neue Haas Grotesk";
  font-style: normal;
  font-weight: 400; /* "font-normal" in Tailwind */
  src: url("/webFonts/NeueHaasGroteskText55Roman/font.woff2") format("woff2"),
    url("/webFonts/NeueHaasGroteskText55Roman/font.woff") format("woff");
}
@font-face {
  font-family: "Neue Haas Grotesk";
  font-style: italic;
  font-weight: 400; /* "font-normal" in Tailwind */
  src: url("/webFonts/NeueHaasGroteskText56Italic/font.woff2") format("woff2"),
    url("/webFonts/NeueHaasGroteskText56Italic/font.woff") format("woff");
}

/* We use "Medium" as our 700 font weight */
@font-face {
  font-family: "Neue Haas Grotesk";
  font-style: italic;
  font-weight: 700; /* "font-bold" in Tailwind */
  src: url("/webFonts/NeueHaasGroteskText66MediumItalic/font.woff2")
      format("woff2"),
    url("/webFonts/NeueHaasGroteskText66MediumItalic/font.woff") format("woff");
}
@font-face {
  font-family: "Neue Haas Grotesk";
  font-style: normal;
  font-weight: 700; /* "font-bold" in Tailwind */
  src: url("/webFonts/NeueHaasGroteskText65Medium/font.woff2") format("woff2"),
    url("/webFonts/NeueHaasGroteskText65Medium/font.woff") format("woff");
}

/* "Bold" is included, but can only be accessed explicitly through the 900 font weight */
@font-face {
  font-family: "Neue Haas Grotesk";
  font-style: italic;
  font-weight: 900; /* "font-black" in Tailwind */
  src: url("/webFonts/NeueHaasGroteskText76BoldItalic/font.woff2")
      format("woff2"),
    url("/webFonts/NeueHaasGroteskText76BoldItalic/font.woff") format("woff");
}
@font-face {
  font-family: "Neue Haas Grotesk";
  font-style: normal;
  font-weight: 900; /* "font-black" in Tailwind */
  src: url("/webFonts/NeueHaasGroteskText75Bold/font.woff2") format("woff2"),
    url("/webFonts/NeueHaasGroteskText75Bold/font.woff") format("woff");
}
