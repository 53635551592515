@tailwind base;
@tailwind components;
@tailwind utilities;

/* Utility class to hide scrollbars */
.scrollbar-hide {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.time-zone-selector {
  input[type="text"]:focus {
    --tw-ring-color: transparent;
  }
}

// Hack because it's not clear how (and might not be possible?) to override `styles/CustomFlowbiteTheme.ts` on a per component basis. https://www.flowbite-react.com/docs/components/tooltip#theme
:is(.dark .dark\:bg-gray-950) .rotate-45 {
  // Using `.rotate-45` as a selector instead of `data-testid="flowbite-tooltip-arrow"` since Flowbite could be more likely to remove a testid.
  @apply bg-gray-950;
}

.list-checkmark {
  list-style-type: none;
}

.list-checkmark li::before {
  content: "✔"; // Unicode for checkmark
  margin-right: 8px;
  color: inherit;
}

.list-checkmark-forteBlue {
  list-style-type: none;
}

.list-checkmark-forteBlue li::before {
  content: "✔"; // Unicode for checkmark
  margin-right: 8px;
  color: #768cdd;
}
