:root {
  /* Flag icon height. */
  /* --PhoneInputCountryFlag-height: 1em; */

  /* Flag icon outline color. */
  --PhoneInputCountryFlag-borderColor: var(
    --tw-ring-color
  ); /*rgba(0,0,0,0.5) */
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);

  /* Country select arrow color. */
  --PhoneInputCountrySelectArrow-color: white;

  /* Country select arrow opacity (when not :focused). */
  --PhoneInputCountrySelectArrow-opacity: 1;

  /* Flag icon :focus outline color, and also country select arrow :focus color. */
  --PhoneInput-color--focus: var(--tw-ring-color);

  --PhoneInputCountrySelectArrow-width: 0.5em;
  --PhoneInputCountryFlag-height: 1em;
}

.PhoneInputCountrySelectArrow {
  color: var(--tw-ring-color);
}

.PhoneInputCountry {
  margin-top: 0.25rem;
  margin-bottom: 0.75rem;
}

.PhoneInputCountryIcon {
  color: white; /* color of the "international flag" */
}
