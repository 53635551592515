/* 
FullCalendar Overrides:

Where possible we should use FullCalendar's built-in variables 
to set overrides as opposed to class targeting.
*/

html,
body {
  --fc-today-bg-color: #768cdd40; /* forteBlue, 40% opacity */
  --fc-border-color: #7c7c7c; /* forteCharcoal 60*/
  --fc-page-bg-color: transparent;
}

.fc-theme-standard table.fc-col-header {
  border-top: 1px solid black !important;
}

tbody[role="rowgroup"] td[role="presentation"],
th[role="presentation"] {
  border-right: none !important;
}

.fc-theme-standard thead {
  border-top: 1px solid black !important;
}

.fc-scrollgrid {
  border: none !important;
}

.fc-timegrid-slots table tr {
  height: 2.5rem;
}

/* Prevents the event from overflowing the cell. Specifically for the month view on narrow screens. */
.fc-daygrid-event-harness {
  overflow: hidden;
}

/* Prevents the column text from overflowing the cell. Specifically for the recurring view on narrow screens. */
.fc .fc-col-header-cell-cushion {
  display: block !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.fc-list-event-time {
  display: none;
}

.fc-list-event-graphic {
  display: none;
}

.fc-list-event-title:hover {
  background-color: transparent !important;
}

.js-lesson-calendar-list-view-only {
  --fc-border-color: transparent;
}

/* Zero it out so we can style it in DayHeader and LessonCalendar components. */
.js-lesson-calendar-list-view-only .fc-list-day-cushion.fc-cell-shaded {
  padding: 0;
}

.js-lesson-calendar-list-view-only .fc-list-event-title {
  padding: 0 !important;
}

/**
 * Allows the list view calendar to display as rounded without using overflow: hidden.
 * This allows tooltip content inside the calendar to display outside the bounds of the calendar.
 */
.js-lesson-calendar-list-view-only .fc-list-table tbody tr:first-child > * {
  border-top-left-radius: 0.35rem;
  border-top-right-radius: 0.35rem;
}
.js-lesson-calendar-list-view-only .fc-list-table tbody tr:last-child > * {
  border-bottom-left-radius: 0.35rem;
  border-bottom-right-radius: 0.35rem;
}
.js-lesson-calendar-list-view-only .fc-list-day-cushion.fc-cell-shaded {
  background-color: transparent;
}
/* End rounded calendar overrides */

.fc-daygrid-day-number {
  color: #fff;
}
